import { useActorRef, useSelector } from '@xstate5/react';
import { match } from 'ts-pattern';

import { JStyleDeviceConnected } from '@strive/jstyle';
import { StrivewareContext, strivewarePairingMachine } from '@strive/wearable';

import { StrivewareHeader } from '../striveware-header';
import { StrivewareLayout } from '../striveware-layout';
import { StrivewareSetupContext } from './hooks';
import { SetupCharging } from './setup-charging';
import { SetupIntro } from './setup-intro';
import { SetupMultipleDevices } from './setup-multiple-devices';
import { SetupNoDevices } from './setup-no-devices';
import { SetupScanning } from './setup-scanning';
import { SetupSingleDevice } from './setup-single-device';

export type StrivewareSetupProps = {
  onCancel?: () => void;
  onComplete?: () => void;
};

export function StrivewareSetup(props: StrivewareSetupProps) {
  const strivewareActor = StrivewareContext.useActorRef();
  const setupActor = useActorRef(
    strivewarePairingMachine.provide({
      actions: {
        onDevicePaired: (
          _: unknown,
          { device }: { device: JStyleDeviceConnected },
        ) => {
          strivewareActor.send({ type: 'Pair Device', device });
          props.onComplete?.();
        },
      },
    }),
  );
  const view = useSelector(setupActor, (state) =>
    state.matches('initial')
      ? 'initial'
      : state.matches('charging')
      ? 'charging'
      : state.matches({ 'Finding Devices': 'Quick Scan' }) ||
        state.matches({ 'Finding Devices': 'Extended Scan' })
      ? 'scanning'
      : state.matches({ 'Finding Devices': { Results: 'Single Device' } })
      ? 'results-single'
      : state.matches({ 'Finding Devices': { Results: 'Multiple Devices' } })
      ? 'results'
      : state.matches({ 'Finding Devices': { Results: 'No Devices' } })
      ? 'no-devices'
      : 'initial',
  );

  const handleCancel = (event: React.MouseEvent) => {
    if (props.onCancel) {
      event.preventDefault();
      props.onCancel();
    }
  };

  return (
    <StrivewareSetupContext.Provider value={setupActor}>
      <StrivewareLayout
        banner={
          view === 'initial' || view === 'charging' ? null : (
            <StrivewareHeader
              label="Pair StriveWare"
              onClickBack={(event) => {
                event.preventDefault();
                setupActor.send({ type: 'Back' });
              }}
            />
          )
        }
      >
        {match(view)
          .with('initial', () => <SetupIntro onBack={handleCancel} />)
          .with('charging', () => <SetupCharging />)
          .with('scanning', () => <SetupScanning />)
          .with('results', () => <SetupMultipleDevices />)
          .with('results-single', () => <SetupSingleDevice />)
          .with('no-devices', () => <SetupNoDevices />)
          .exhaustive()}
      </StrivewareLayout>
    </StrivewareSetupContext.Provider>
  );
}
